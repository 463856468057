import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type Props = {};

const SummitDetails = (props: Props) => {
  return (
    <React.Fragment>
      <section id="aboutsummit" className="">
        <Box
          // className="h-[1200px]"
          justifyContent="center"
          alignItems="center"
          sx={{
            "& svg": {
              color: "common.white",
            },
            "& button:hover svg": {
              color: "primary.main",
            },
          }}
        >
          <Typography
            variant="caption"
            fontSize={{ xs: "15px", sm: "18px" }}
            fontWeight={900}
            lineHeight={{ xs: "247%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className="pt-16 md:pt-20 uppercase w-[335px] md:w-[760px] lg:w-[846px] mx-auto text-center flex justify-center"
          >
            The Urgency of Care
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="text-center py-10 w-[335px] md:w-[760px] lg:w-[846px] mx-auto flex justify-center"
          >
            Now, more than ever, we need a global response—one that places care
            at the heart of national and international agendas, just as we’ve
            done with climate change.
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="text-center py-2 w-[335px] md:w-[760px] lg:w-[846px] mx-auto  justify-center"
          >
            <b className=" font-areaNorm">The Global Care Gap Tour + Summit </b>
            will travel across five cities—{" "}
            <b className=" font-areaNorm">
              Lagos, Washington DC, Austin, Los Angeles, and London
            </b>
            —uniting thought leaders, policymakers, businesses, and communities
            to address the care crisis head-on. Our goal? To push for real,
            actionable solutions that elevate care as a pillar of social and
            economic progress.
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="text-center py-2 w-[335px] md:w-[760px] lg:w-[846px] mx-auto  justify-center"
          >
            This tour comes on the heels of a significant achievement—{" "}
            <a
              className=" font-areaNorm underline"
              target="_blanc"
              href="https://www.t20brasil.org/media/documentos/arquivos/TF01_ST06__Inclusive_access_to66e1942ca84d9.pdf"
            >
              our policy brief
            </a>{" "}
            on inclusive care infrastructure has been accepted at the{" "}
            <b className=" font-areaNorm">T20 Brazil</b>
            ahead of the <b className=" font-areaNorm">G20 Summit,</b> setting
            the stage for a global care conversation. Now more than ever, we
            need to push this agenda forward and create the momentum for
            systemic change across nations.
          </Typography>
          <Box className="hidden py-12 lg:flex lg:justify-center lg:gap-10 ">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-1.jpeg"
              alt=""
              className="w-[160px] h-[160px] md:w-[401px] md:h-[401px] rounded-[6px] my-4 object-cover"
            />
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-2.jpeg"
              alt=""
              className="w-[160px] h-[160px] md:w-[401px] md:h-[401px] rounded-[6px] my-4 object-cover"
            />
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-3.jpeg"
              alt=""
              className="w-[160px] h-[160px] md:w-[401px] md:h-[401px] rounded-[6px] my-4 object-cover"
            />
          </Box>

          {/* Tab & Mobile Image */}

          <Box className="block lg:hidden h-[400px] w-[360px] md:h-[800px] md:w-[760px] mx-auto relative">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-1.jpeg"
              alt=""
              className="w-[160px] h-[160px] md:w-[350px] md:h-[350px] rounded-[6px] object-cover absolute top-[5%] md:top-[5%] z-10 left-[30%]"
            />

            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-2.jpeg"
              alt=""
              className="w-[160px] h-[160px] md:w-[350px] md:h-[350px] rounded-[6px] object-cover absolute top-[35%] md:top-[40%] right-[2%]"
            />

            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-3.jpeg"
              alt=""
              className="w-[160px] h-[160px] md:w-[350px] md:h-[350px] rounded-[6px] object-cover absolute top-[50%] md:top-[55%] left-[5%]"
            />
          </Box>

          {/* Tab & Mobile Image Ends */}

          {/* <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className=" py-6 uppercase text-center flex justify-center"
        >
          past sponsors
        </Typography>

        <Grid
          container
          spacing={6}
          className="px-10 md:px-12 lg:pl-32 py-4 items-center place-content-center"
        >
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <img
              src="https://flourish.motherhonestly.com/asset/indeed.png"
              alt="Indeed"
              className="w-[150px] object-cover"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <img
              src="https://flourish.motherhonestly.com/asset/mamava.png"
              alt="Mamava"
              className="w-[150px] object-cover"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <img
              src="https://flourish.motherhonestly.com/asset/vivi.png"
              alt="Vivvi"
              className="w-[150px] object-cover"
            />
          </Grid>
        </Grid>

        <hr className="my-8 hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300" /> 
        
        */}
        </Box>
      </section>
    </React.Fragment>
  );
};

export default SummitDetails;
