import React from "react";

import Grid from "@mui/material/Grid";

import MHFormGroup from "../Common/Form/MHFormGroup";
import MHFormControl from "../Common/Form/MHFormControl";
import MHButton from "../Common/Button/MHButton";
import useInput from "../../hooks/use-input";

import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";
import { resolveErrorMessage } from "../../utils/utils";
import useForm from "../../hooks/use-form";
import usePhoneInput from "../../hooks/use-phone";
import MHPhoneInput from "../Common/PhoneInput/MHPhoneInput";
import MHAutocomplete, { Option } from "../Common/Form/MHAutocomplete";
import SnackbarContext from "../../store/context/snackbar.context";

const InPersonRegistration = (props: {
  title?: string;
  onComplete: () => void;
}) => {
  const {
    value: enteredFullName,
    valid: enteredFullNameIsValid,
    error: enteredFullNameHasError,
    onChange: fullNameInputChangeHandler,
    onBlur: fullNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredGender,
    valid: enteredGenderIsValid,
    error: enteredGenderHasError,
    onChange: genderInputChangeHandler,
    onBlur: genderInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredTour,
    valid: enteredTourIsValid,
    error: enteredTourHasError,
    onChange: tourInputChangeHandler,
    onBlur: tourInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredJobTitle,
    valid: enteredJobTitleIsValid,
    error: enteredJobTitleHasError,
    onChange: jobTitleInputChangeHandler,
    onBlur: jobTitleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const genderRolesOptions: Option[] = constants.GENDER.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const tourOptions: Option[] = constants.TOUR2024.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check

  const formIsValid =
    enteredFullNameIsValid &&
    enteredEmailIsValid &&
    enteredCompanyNameIsValid &&
    enteredJobTitleIsValid &&
    enteredGenderIsValid &&
    phoneUseNumberIsValid &&
    enteredTourIsValid;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });
  // Split the full name into an array of words
  let nameArray = enteredFullName.split(" ");

  // Extract the first and last names
  let firstName = nameArray[0];
  let lastName = nameArray[nameArray.length - 1];

  const flodeskSubmit = async () => {
    // e.preventDefault();
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "The Care Gap (www.thecaregap.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
        }),
      });
      flodeskSegmentSubmit();
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651308b6fc3c5dd11b428798"]; // Replace with actual segment IDs
    // const testsegmentIds = ["64edd24f139c9cfad37e4808"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "The Care Gap (www.thecaregap.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Form valid status:", formIsValid);

    validateForm();

    if (!formIsValid) {
      toast({
        message: "Please input all required field",
        variant: "warning",
      });
    }

    props.onComplete();

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "the-care-gap-tour-2024"), {
      fullName: enteredFullName,
      email: enteredEmail,
      gender: enteredGender,
      phone: phoneNumber,
      tour: enteredTour,
      company: enteredCompanyName,
      jobTitle: enteredJobTitle,
      createdAt: serverTimestamp(),
    });
    toast({
      message:
        "Thank you for your interest in this tour, we will revert back at you shortly",
      variant: "success",
    });

    // handleOpenDialog();
    flodeskSubmit();

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const { validateForm } = useForm();

  return (
    <React.Fragment>
      <MHFormGroup
        onSubmit={submitHandler}
        disableWhileSubmitting={httpState.loading}
      >
        <Grid container spacing={1} className="lg:px-8">
          <Grid item xs={12} sm={6}>
            <MHFormControl
              id="fullName"
              type="text"
              label="Full Name"
              placeholder="Enter Full Name"
              value={enteredFullName}
              onChange={fullNameInputChangeHandler}
              onBlur={fullNameInputBlurHandler}
              error={resolveErrorMessage(enteredFullNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MHFormControl
              id="email"
              type="email"
              label="Email"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              error={resolveErrorMessage(enteredEmailHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MHPhoneInput
              value={phoneNumber}
              onChange={phoneNumberInputChangeHandler}
              label="Phone Number"
              errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                "Please enter a valid phone number"
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MHAutocomplete
              label="Gender"
              placeholder="Select Gender"
              options={genderRolesOptions}
              onInputChange={(val) => genderInputChangeHandler(val as string)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MHAutocomplete
              label="Select Tour"
              placeholder="Please select the tour you wish to join"
              options={tourOptions}
              onInputChange={(val) => tourInputChangeHandler(val as string)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MHFormControl
              id="companyName"
              type="text"
              label="Company Name"
              placeholder="Enter Company Name"
              value={enteredCompanyName}
              onChange={companyNameInputChangeHandler}
              onBlur={companyNameInputBlurHandler}
              error={resolveErrorMessage(enteredCompanyNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MHFormControl
              id="jobTitle"
              type="text"
              label="Job Title"
              placeholder="Enter Job Title"
              value={enteredJobTitle}
              onChange={jobTitleInputChangeHandler}
              onBlur={jobTitleInputBlurHandler}
              error={resolveErrorMessage(enteredJobTitleHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} className="lg:px-1 lg:pl-3 mb-8">
            <MHButton
              type="submit"
              fullWidth
              loading={httpState.loading}
              className="text-[12px] leading-[102%] w-full tracking-[0.05em] md:h-[52px] "
            >
              Submit
            </MHButton>
          </Grid>
        </Grid>
      </MHFormGroup>
    </React.Fragment>
  );
};

export default InPersonRegistration;
