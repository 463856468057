import Drawer from "@mui/material/Drawer";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { DRAWER_WIDTH } from "../../utils/constants";
import { PopperLink } from "./NavPrimaryLinks";
import { useHistory, Link } from "react-router-dom";
import { MHMenuItem } from "../Common/MenuPopper/MHMenu";
import { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";

export const MobileSidebarLink = styled(Link)(({ theme }) => ({
  color: "#194049",
  lineHeight: "92%",
  textTransform: "capitalize",
  listStyle: "none",
  listStyleType: "none",
  display: "block",
  // [theme.breakpoints.down('sm')]: {
  //   display: 'none'
  // },
  // ...theme.typography.subtitle1,
  fontFamily: "Area-Normal-Bold !important",
  cursor: "pointer",
  textDecoration: "none",
  fontSize: ".95rem",
  marginBlockEnd: 36,
  "&:hover": {
    textDecoration: "none",
  },
}));

export const ResourcesDrawerDropdownContent = () => {
  const history = useHistory();

  return (
    <Stack pl={3} mb="36px">
      <Box>
        <Typography
          variant="subtitle2"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          fontSize=".65rem"
          mb={3}
          sx={{
            opacity: ".5",
          }}
        >
          Resources
        </Typography>

        <PopperLink onClick={() => history.push("/resources/toolkits")}>
          Toolkits
        </PopperLink>
        <PopperLink onClick={() => history.push("/resources/articles")}>
          Articles
        </PopperLink>
        <PopperLink onClick={() => history.push("/resources/podcasts")}>
          Podcasts
        </PopperLink>
        <PopperLink onClick={() => history.push("/resources/videos")}>
          Videos
        </PopperLink>
        <PopperLink onClick={() => history.push("/resources/events")}>
          Events
        </PopperLink>
        {/* <PopperLink>All</PopperLink> */}
      </Box>

      <Box>
        <Typography
          variant="subtitle2"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          fontSize=".65rem"
          my={3}
          sx={{
            opacity: ".5",
          }}
        >
          Explore by topic
        </Typography>

        <PopperLink>Career</PopperLink>
        <PopperLink>Family</PopperLink>
        <PopperLink>Household</PopperLink>
        <PopperLink>Finances</PopperLink>
        <PopperLink>Wellbeing</PopperLink>
      </Box>
    </Stack>
  );
};

export const GuidedSessionsDrawerDropdownContent = () => {
  const history = useHistory();

  const GUIDED_SESSIONS = [
    {
      label: "Care Plan",
      link: "/individuals",
    },
    {
      label: "1:1 Sessions",
      link: "/advisors",
    },
    {
      label: "Cohort Sessions",
      link: "/cohort-sessions",
    },
  ];

  return (
    <Stack pl={3} mb="36px">
      <Box>
        {/* <Typography
          variant="subtitle2"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          fontSize=".65rem"
          mb={3}
          sx={{
            opacity: '.5'
          }}>
          Guided Sessions
        </Typography> */}

        {GUIDED_SESSIONS.map((item) => (
          <PopperLink onClick={() => history.push(item.link)}>
            {item.label}
          </PopperLink>
        ))}
      </Box>
    </Stack>
  );
};

export const EmployersDrawerDropdownContent = () => {
  const history = useHistory();

  const EMPLOYERS_SESSIONS = [
    {
      label: "Care Academy",
      link: "/care-academy",
    },
    {
      label: "Care Coverage",
      link: "/leave-gap",
    },
    {
      label: "Care Fund",
      link: "/care",
    },
  ];

  return (
    <Stack pl={3} mb="36px">
      <Box>
        {/* <Typography
          variant="subtitle2"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          fontSize=".65rem"
          mb={3}
          sx={{
            opacity: '.5'
          }}>
          Guided Sessions
        </Typography> */}

        {EMPLOYERS_SESSIONS.map((item) => (
          <PopperLink onClick={() => history.push(item.link)}>
            {item.label}
          </PopperLink>
        ))}
      </Box>
    </Stack>
  );
};

type Props = {
  title?: string | any;
  date?: string | any;
  caption?: string | any;
  slug?: string | any;
  imgSrc?: string | any;
  imageList?: string[] | any;
  createdAt?: string | any;
};

export const SummitDrawerDropdownContent = () => {
  const history = useHistory();

  const [summitData, setSummitData] = useState<Props[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db, "mh-past-summit"); // Replace with your actual collection name
        const querySnapshot = await getDocs(
          query(collectionRef, orderBy("slug", "asc"))
        );

        const data = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as Props),
        }));

        setSummitData(data);
        console.log(data, "Data");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const SUMMIT_YEAR = [
    {
      label: "2024",
      onClick: () => history.push("/summit"),
    },
    ...summitData.map((event, item) => ({
      label: event.date?.toString().slice(-4), // Use optional chaining to handle possible undefined
      onClick: () =>
        history.push(
          `/recap-summit/${event.date?.toString().slice(-4)}/${event.slug}`
        ),
    })),
  ];

  // Sort the array by the 'label' property in descending order
  SUMMIT_YEAR.sort((a, b) => {
    if (a.label && b.label) {
      return parseInt(b.label) - parseInt(a.label);
    }
    return 0;
  });

  return (
    <Stack pl={3} mb="36px">
      <Box>
        {/* <Typography
          variant="subtitle2"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          fontSize=".65rem"
          mb={3}
          sx={{
            opacity: '.5'
          }}>
          Guided Sessions
        </Typography> */}

        {SUMMIT_YEAR.map((item) => (
          <PopperLink onClick={item.onClick}>{item.label}</PopperLink>
        ))}
      </Box>
    </Stack>
  );
};

const MobileSidebar = styled(
  Drawer,
  {}
)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1000,
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    height: "100vh",
    // paddingTop: theme.spacing(5),
    background: theme.palette.common.white,
    borderWidth: 0,
    boxShadow: "2px 4px 4px 0px #B7B7B740",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default MobileSidebar;
