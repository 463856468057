import React from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  matchPath,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Layout from "../components/Layout/Layout";
import Index from "../pages/Index";

import ResourcesNavigator from "./ResourcesNavigator";
import AuthNavigator from "./AuthNavigator";

import { FnComponent } from "../models/component.model";
import { StaticDataContextProvider } from "../store/context/static-data.context";
import Summit from "../pages/Summit";
import FAQ from "../pages/FAQ";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsCondition from "../pages/TermsCondition";
import ErrorPage from "../pages/404NotFound";
import Checkout from "../components/Features/Checkout/Checkout";
import Cart from "../components/Features/Checkout/Cart";
import CartCheckout from "../components/Features/Checkout/CartCheckout";
import BrandPartnership from "../pages/BrandPartnership";
import OurStory from "../pages/OurStory";
import Employers from "../pages/Employers";
import PlacementsNavigator from "./PlacementsNavigator";
import { SearchContextProvider } from "../store/context/search-context";
import CareWallet from "../pages/CareWallet";
import AuthContext from "../store/context/auth-context";
import Equity from "../pages/Equity";
import CaringWorkplace from "../pages/CaringWorkplace";
import CaringLeader from "../pages/CaringLeader";
import GuardedRoute from "../components/Features/Auth/GuardedRoute";
import EmployersTempRedacted from "../pages/EmployersTempRedacted";
import SpeakerRequest from "../pages/SpeakerRequest";
import Quotient from "../pages/Quotient";
import Africa from "../pages/Africa";
import GuidanceSessionsNavigator from "./GuidanceSessionsNavigator";
import CohortSessionsNavigator from "./CohortSessionsNavigator";
import CareAcademy from "../pages/CareAcademy";
import RegSummitPartner from "../pages/RegSummitPartner";
import AfricaSummit from "../pages/AfricaSummit";
import PastSummit from "../pages/PastSummit";
import CalendlyEmbed from "../pages/CalendlyEmbed";
import RefundPolicy from "../pages/RefundPolicy";
import VoteForCare from "../pages/VoteLikeYouCare";
import VoteLikeYouCare from "../pages/VoteLikeYouCare";

const MEMBERSIPS_PATHNAME = "/votelikeyoucare";

const AppNavigator: FnComponent<{}> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const { isAuthenticated, openAuthDialog, closeAuthDialog } =
    React.useContext(AuthContext);

  function handleOnAuthSuccess() {
    history.replace(location.pathname);
  }

  function handleTerminateAuth() {
    /**
     * path matches that prevent redirect to homepage when auth dialog is terminated
     */
    const pathMatches = matchPath(window.location.pathname, {
      path: [
        "/individuals",
        "/auth/subscribe",
        "/auth/login",
        "/auth/forgot-password",
      ],
      exact: false,
      strict: false,
    });

    // console.log(window.location.pathname);

    if (!pathMatches) {
      history.replace("/");
    }
  }

  return (
    <React.Fragment>
      <StaticDataContextProvider>
        <SearchContextProvider>
          <Layout>
            {/* <TransitionGroup>
              <CSSTransition
                unmountOnExit
                key={location.pathname}
                classNames="fade"
                timeout={400}> */}
            <Switch location={location}>
              <Route path={`/`} exact>
                <Index />
                {/* <EmployersTempRedacted /> */}
              </Route>

              <Route path={`/resources`}>
                <ResourcesNavigator isComponentAccessible={true} />
              </Route>

              {/* <Route path={`/employer-temp-fsdgfgdfh`} exact>
                <EmployersTempRedacted />
              </Route> */}
              <Route path={`/schedule`} exact>
                <CalendlyEmbed />
              </Route>
              <Route path={`/employers`} exact>
                <Employers />
              </Route>
              <Route path={`/care`} exact>
                <CareWallet />
              </Route>
              <Route path={`/summit`} exact>
                <Summit />
              </Route>
              <Route path={`/recap-summit/:year/:slug`} exact>
                <PastSummit />
              </Route>
              {/* <Route path={`/africa/summit`} exact>
                <AfricaSummit />
              </Route> */}
              <Route path={`/summit/partners/registration`} exact>
                <RegSummitPartner />
              </Route>
              {/* <Route path={`/summit/caring-workplace`} exact>
                <CaringWorkplace />
              </Route>
              <Route path={`/summit/caring-leader`} exact>
                <CaringLeader />
              </Route> */}
              <Route path={`/summit/speaker-request`} exact>
                <SpeakerRequest />
              </Route>
              <Route path={`/ourstory`} exact>
                <OurStory />
              </Route>
              <Route path={`/partnership`} exact>
                <BrandPartnership />
              </Route>
              <Route path={`/care-equity`} exact>
                <Equity />
              </Route>
              <Route path={`/advisory`} exact>
                <CareAcademy />
              </Route>
              <Route path={`/newsletter`} exact>
                <Quotient />
              </Route>
              {/* <Route path={`/africa`} exact>
                <Africa />
              </Route> */}
              {/* <Route path={`/faq`} exact>
                <FAQ />
              </Route> */}
              <Route path={`/contact`} exact>
                <Contact />
              </Route>
              <Route path={`/privacy-policy`} exact>
                <PrivacyPolicy />
              </Route>
              <Route path={`/refund-policy`} exact>
                <RefundPolicy />
              </Route>
              <Route path={`/terms-and-condition`} exact>
                <TermsCondition />
              </Route>

              {/* <Route path={`/votelikeyoucare`}>
                <VoteLikeYouCare />
              </Route> */}

              {/* <Route path={`/advisors`}>
                <GuidanceSessionsNavigator />
              </Route>
              <Route path={`/cohort-sessions`}>
                <CohortSessionsNavigator />
              </Route>
              <Route path={`/leave-gap`}>
                <PlacementsNavigator />
              </Route>
              
              <Route path={`/checkout`}>
                <Checkout />
              </Route>
              <Route path={`/cart`}>
                <Cart />
              </Route>
              <Route path={`/cart-checkout`}>
                <CartCheckout />
              </Route> */}

              {/* <Route path={`/auth`}>
                <AuthNavigator />
              </Route> */}
              <Route path={`*`}>
                <ErrorPage />
              </Route>
            </Switch>
            {/* </CSSTransition>
            </TransitionGroup> */}
          </Layout>
        </SearchContextProvider>
      </StaticDataContextProvider>
    </React.Fragment>
  );
};

export default AppNavigator;
