import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React from "react";

import { ReactComponent as YellowIcon } from "../../static/svg/eventyellow.svg";

type Props = {};

const SummitMotto = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="bg-navy-900 h-[420px] lg:h-[340px]">
        <Box className="py-12 md:py-8 lg:py-12">
          <YellowIcon className="mx-auto h-8 w-8" />
          <Typography
            variant="h2"
            fontSize={{ xs: "24px", sm: "30px", md: "32px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            className="py-10 lg:py-8 w-[88%] md:w-[75%] lg:w-[60%] text-center mx-auto"
            color={"white"}
            justifyContent="center"
            alignItems="center"
          >
            The Care Gap Tour & Summit is more than an event – it’s a movement
            to close the gender care gap and create a more equitable future for
            families and caregivers. Join us as we drive actionable solutions
            and shape workplaces that prioritize care and support for all.
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SummitMotto;
