import { Box } from "@mui/material";
import React from "react";

type Props = {};

const RecapVideo = (props: Props) => {
  return (
    <React.Fragment>
      <Box bgcolor="primary.main">
        <Box>
          <iframe
            src="https://player.vimeo.com/video/1017604979?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-full h-[220px] md:h-[460px] lg:h-[805px]"
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default RecapVideo;
