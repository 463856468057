import React, { useState } from "react";
import { FormControl, TextField, Typography, Box, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";

const StyledInputRoot = styled("div")`
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in;
  position: relative;

  &.MuiInput-root::placeholder {
    color: #a6a6a6;
  }

  &.MuiInput-root.Mui-disabled input {
    // background-color: #f5f5f5;
    color: #a6a6a6;
  }

  &.MuiInput-root:hover {
    border: none;
  }
`;

const StyledInputElement = styled("input")`
  font-size: 0.75rem;
  font-weight: 400;
  height: 50px;
  line-height: 200%;
  letter-spacing: 0.02em;
  flex-grow: 1;
  color: #194049;
  background: #f9f9f7;
  padding: 16.5px 14px;
  outline: 0;
  &::placeholder {
    color: #194049;
    opacity: 0.5;
  }
`;

export interface Option {
  label: string;
  value: string;
}

interface AutocompleteProps {
  label: string;
  placeholder?: string;
  options: Option[];
  onInputChange: (value: string) => void;
}

const MHAutocomplete: React.FC<AutocompleteProps> = ({
  label,
  placeholder,
  options,
  onInputChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const handleOptionClick = (option: Option) => {
    setSelectedValue(option);
    onInputChange(option.value);
  };

  return (
    <FormControl fullWidth>
      {label && (
        <label
          htmlFor="autocomplete-input"
          className=" font-areaSemi"
          style={{
            fontFamily: "Area-Normal-Black",
            lineHeight: "170%",
            textTransform: "uppercase",
            color: "#194049",
            display: "inline-block",
            width: "100%",
          }}
        >
          {label}
        </label>
      )}
      <Autocomplete
        id="autocomplete-input"
        options={options}
        value={selectedValue} // Ensure value is the full Option object
        isOptionEqualToValue={(option, value) => option.value === value.value} // Customize equality check
        getOptionLabel={(option: Option) => option.label || ""}
        onChange={(event, newValue) => {
          if (newValue) handleOptionClick(newValue);
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none", // Remove the border of the input
            "&:hover fieldset": {
              borderColor: "transparent", // Remove border on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent", // Remove border when focused
            },
          },
        }}
        renderOption={(props, option: Option) => (
          <li {...props}>
            <Button
              onClick={() => handleOptionClick(option)}
              className="removeUnderline no-underline text-start"
              fullWidth
              sx={{
                justifyContent: "start", // Aligns button content to the start
              }}
            >
              <Box
                component="li"
                sx={{
                  display: "flex",
                  justifyContent: "start",
                }}
                padding={{ xs: 1, sm: 1 }}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  lineHeight={{ xs: "0px", sm: "1px" }}
                  textAlign={{ xs: "start", sm: "start" }}
                  justifyContent="start"
                  color="primary"
                  gutterBottom
                >
                  {option.label}
                </Typography>
              </Box>
            </Button>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              components: {
                Root: StyledInputRoot,
                Input: StyledInputElement,
              },
              sx: {
                "& fieldset": {
                  border: "none", // Remove the border from the TextField
                },
                "&:hover fieldset": {
                  border: "none", // No border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "none", // No border on focus
                },
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default MHAutocomplete;
