import React from "react";

import Grid from "@mui/material/Grid";

import MHFormGroup from "../Common/Form/MHFormGroup";
import MHFormControl from "../Common/Form/MHFormControl";
import MHButton from "../Common/Button/MHButton";
import useInput from "../../hooks/use-input";

import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";
import { resolveErrorMessage } from "../../utils/utils";
import { MHMultiSelect, MHSelect } from "../Common/Form/MHSelect";
import StackedRow from "../UI/StackedRow";
import { SelectOption } from "@mui/base";
import { styled } from "@mui/material/styles";
import useMultiSelect from "../../hooks/use-multi-select";
import useForm from "../../hooks/use-form";

const ManagerPassRegistration = (props: { onComplete: () => void }) => {
  const {
    value: enteredFullName,
    valid: enteredFullNameIsValid,
    error: enteredFullNameHasError,
    onChange: fullNameInputChangeHandler,
    onBlur: fullNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredGender,
    valid: enteredGenderIsValid,
    error: enteredGenderHasError,
    onChange: genderInputChangeHandler,
    onBlur: genderInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEthnicity,
    valid: enteredEthnicityIsValid,
    error: enteredEthnicityHasError,
    onChange: ethnicityInputChangeHandler,
    onBlur: ethnicityInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredJobTitle,
    valid: enteredJobTitleIsValid,
    error: enteredJobTitleHasError,
    onChange: jobTitleInputChangeHandler,
    onBlur: jobTitleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredChallenge,
    valid: enteredChallengeIsValid,
    error: enteredChallengeHasError,
    onChange: challengeInputChangeHandler,
    onBlur: challengeInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredExpectation,
    valid: enteredExpectationIsValid,
    error: enteredExpectationHasError,
    onChange: expectationInputChangeHandler,
    onBlur: expectationInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const { values: interests, inputChangeHandler: interestInputChangeHandler } =
    useMultiSelect();

  const formIsValid =
    enteredFullNameIsValid &&
    enteredEmailIsValid &&
    enteredCompanyNameIsValid &&
    enteredGenderIsValid &&
    enteredEthnicityIsValid &&
    enteredJobTitleIsValid &&
    enteredChallengeIsValid &&
    enteredExpectationIsValid &&
    interests &&
    interests.length > 0;

  const SelectTag = styled("span")(
    ({ theme }) => `
      border-radius: 3px;
      background: #EBE5F1;
      padding: 4px 9px;
      display: inline-block;
      color: ${theme.palette.primary.main};
      width: fit-content;
    `
  );

  function renderValue(options: SelectOption<string>[] | null) {
    let content = null;

    if (!options) return content;

    return (
      <StackedRow
        spacing={1}
        maxWidth="calc(100% - 30px)"
        sx={{
          //   whiteSpace: 'nowrap',
          //   overflowX: 'hidden',
          "::-webkit-scrollbar": {
            height: "0px",
          },
        }}
      >
        {options.map((item) => (
          <SelectTag key={item.value}>{item.label}</SelectTag>
        ))}
      </StackedRow>
    );
  }

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  // Split the full name into an array of words
  let nameArray = enteredFullName.split(" ");

  // Extract the first and last names
  let firstName = nameArray[0];
  let lastName = nameArray[nameArray.length - 1];

  const flodeskSubmit = async () => {
    // e.preventDefault();
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "MH WorkLife (www.mhworklife.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
        }),
      });
      flodeskSegmentSubmit();
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651308b6fc3c5dd11b428798"]; // Replace with actual segment IDs
    // const testsegmentIds = ["64edd24f139c9cfad37e4808"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "MH WorkLife (www.mhworklife.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    props.onComplete();

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "in-person-summit-registrations"), {
      fullName: enteredFullName,
      jobTitle: enteredJobTitle,
      email: enteredEmail,
      company: enteredCompanyName,
      gender: enteredGender,
      ethnicity: enteredEthnicity,
      significantChallenge: enteredChallenge,
      topicOfInterest: interests?.join(", "),
      expectation: enteredExpectation,
      createdAt: serverTimestamp(),
    });

    // handleOpenDialog();
    flodeskSubmit();

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const { validateForm } = useForm();

  return (
    <React.Fragment>
      <MHFormGroup
        onSubmit={submitHandler}
        disableWhileSubmitting={httpState.loading}
      >
        <Grid container spacing={1} className="lg:px-8">
          <Grid item xs={12}>
            <MHFormControl
              id="fullName"
              type="text"
              label="Full Name"
              placeholder="Enter Full Name"
              value={enteredFullName}
              onChange={fullNameInputChangeHandler}
              onBlur={fullNameInputBlurHandler}
              error={resolveErrorMessage(enteredFullNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHFormControl
              id="email"
              type="email"
              label="Email"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              error={resolveErrorMessage(enteredEmailHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHSelect
              label="Gender"
              placeholder="select your gender"
              options={constants.GENDER}
              value={enteredGender}
              onChange={(val) => genderInputChangeHandler(val as string)}
              onBlur={genderInputBlurHandler}
              error={resolveErrorMessage(enteredGenderHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHSelect
              label="Ethnicity"
              placeholder="select your ethnicity"
              options={constants.ETHNICITY_OPTIONS}
              value={enteredEthnicity}
              onChange={(val) => ethnicityInputChangeHandler(val as string)}
              onBlur={ethnicityInputBlurHandler}
              error={resolveErrorMessage(enteredEthnicityHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHFormControl
              id="companyName"
              type="text"
              label="Company Name"
              placeholder="Enter Company Name"
              value={enteredCompanyName}
              onChange={companyNameInputChangeHandler}
              onBlur={companyNameInputBlurHandler}
              error={resolveErrorMessage(enteredCompanyNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHFormControl
              id="jobTitle"
              type="text"
              label="Job Title"
              placeholder="Enter Job Title"
              value={enteredJobTitle}
              onChange={jobTitleInputChangeHandler}
              onBlur={jobTitleInputBlurHandler}
              error={resolveErrorMessage(enteredJobTitleHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHMultiSelect
              label="Please indicate which of these topics are of interest to you and your team:"
              placeholder="Please indicate which of these topics are of interest to you and your team:"
              options={constants.MANAGER_INTEREST_TOPICS}
              value={interests as string[]}
              onChange={interestInputChangeHandler}
              onBlur={() => {}}
              renderValue={renderValue}
            />

            {/* <MHMultiSelect
              label="Interests"
              placeholder="What are your interests?"
              options={interestsData.map((interest) => ({
                label: interest.interest,
                value: interest.interest
              }))}
              value={interests as string[]}
              onChange={interestInputChangeHandler}
              renderValue={renderValue}
            /> */}
          </Grid>

          <Grid item xs={12} className="pb-4">
            <MHFormControl
              id="message"
              type="text"
              label="Have you faced any work and life challenges related to caregiving? If so please describe."
              placeholder="Have you faced any work and life challenges related to caregiving? If so please describe."
              value={enteredChallenge}
              onChange={challengeInputChangeHandler}
              onBlur={challengeInputBlurHandler}
              error={resolveErrorMessage(enteredChallengeHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              multiline
              rows={5}
            />
          </Grid>

          <Grid item xs={12} className="pb-4">
            <MHFormControl
              id="message"
              type="text"
              label="What are your expectations for this summit as a manager or leader?"
              placeholder="What are your expectations for this summit as a manager or leader?"
              value={enteredExpectation}
              onChange={expectationInputChangeHandler}
              onBlur={expectationInputBlurHandler}
              error={resolveErrorMessage(enteredExpectationHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              multiline
              rows={5}
            />
          </Grid>

          <Grid item xs={12} className="lg:px-1 lg:pl-3 mb-8">
            <MHButton
              type="submit"
              fullWidth
              loading={httpState.loading}
              className="text-[12px] leading-[102%] w-full tracking-[0.05em] md:h-[52px] "
            >
              Submit
            </MHButton>
          </Grid>
        </Grid>
      </MHFormGroup>
    </React.Fragment>
  );
};

export default ManagerPassRegistration;
