import React from "react";
import Box from "@mui/material/Box";
import MHDialog from "../Common/Dialog/MHDialog";
import MHButton from "../Common/Button/MHButton";
import InPersonRegistration from "./InPersonRegistration";
import ManagerPassRegistration from "./ManagerPassRegistration";
import { Grid } from "@mui/material";

type RegDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  onComplete: () => void;
};

const RegistrationDialog = (props: RegDialogProps) => {
  const handleComplete = () => {
    props.onComplete();
    props.onClose();
  };

  return (
    <MHDialog
      open={props.open}
      title={props.title}
      handleClose={props.onClose}
      scroll="paper"
      actions={null}
      sx={
        {
          // minWidth: '100%',
        }
      }
      maxWidth={"sm"}
      fullWidth
    >
      <Box>
        {props.title.toLowerCase().includes("tour") ? (
          <InPersonRegistration
            title={props.title}
            onComplete={handleComplete}
          />
        ) : (
          <ManagerPassRegistration onComplete={handleComplete} />
        )}
      </Box>
    </MHDialog>
  );
};

export default RegistrationDialog;
