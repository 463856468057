import React, { Fragment } from "react";
import AllresHeader from "../Resources/SubComponents/AllresHeader";

import SummitHeaderCard from "./SummitHeaderCard";
import SponsorDialog from "../PastSummit/SponsorDialog";
import useDialog from "../../hooks/use-dialog";
import { ReactComponent as YellowIcon } from "../../static/svg/eventyellow.svg";
import RegistrationDialog from "./RegistrationDialog";

type Props = {};

const SummitHeader = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <SummitHeaderCard
        headerClassName="relative text-center pl-0 mx-auto h-fit md:h-[620px] lg:h-[620px] w-full bg-[#194049] overflow-hidden"
        majorClassName="md:absolute pt-12 md:top-12 md:inset-x-[10%] lg:top-12 lg:inset-x-[60%] text-center place-content-center"
        boxClassName=" pb-6 pl-32 md:pl-0 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        podClassName="hidden"
        title="GLOBAL TOUR"
        titleInfo="THE CARE GAP TOUR + SUMMIT"
        titleExt="Lagos, Washington D.C, Austin, Los Angeles, and London"
        titleLocation=""
        titleInfoclassName="md:text-left absolute left-8 md:left-0 md:relative justify-center mt-4 mb:mt-0 mb-6 w-[80%] md:w-[460px] lg:w-[400px] font-columbia text-[38px] capitalize font-[500]"
        pageInfo="Confronting the global care crisis head-on; driving actionable solutions to close the gender care gap."
        pageInfoClassName="capitalize line-clamp-4 font-semibold mt-60 md:mt-0 pl-4 md:pl-0 text-center mx-auto pt-4 md:text-left w-[80%] md:ml-0 md:w-[540px] lg:w-[440px] text-[16px] md:text-[22px] leading-[140%] font-areaSemi"
        ResIconUrl={<YellowIcon className=" h-6 w-6" />}
        BgUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/caw2024-avss.png"
        BgMobUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/caw2024Mob-avs.png"
        // CURRENT SUMMIT PAGE BUTTONS
        onClickReg={() => {
          window.open(
            "https://us06web.zoom.us/meeting/register/tZErd-qrqz4jHtFTOZf0eLev54fQuQrNfZ0M"
          );
        }}
        onClickVideo={() => {
          handleOpenDialog();
        }}
        // RECAP SUMMIT PAGES BUTTONS
        onClickWatch={() => {
          window.open("https://vimeo.com/manage/videos/878890141");
          handleOpen();
        }}
        onClickSponsor={() => {
          handleOpen();
        }}
      >
        <p className="hidden">hello</p>
      </SummitHeaderCard>

      {/*  SPONSOR OUR NEXT SUMMIT */}
      <SponsorDialog open={open} onClose={handleClose} />

      {/* {openDialog && (
        <RegistrationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          title={"Registration"}
          onComplete={handleCloseDialog}
        />
      )} */}
      {openDialog && (
        <RegistrationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          title={"Join The Tour"}
          onComplete={handleCloseDialog}
        />
      )}
    </Fragment>
  );
};

export default SummitHeader;
