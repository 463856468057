import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import StatsCard from "./StatsCard";

// type Props = {}

const SummitStats = () => {
  return (
    <React.Fragment>
      <Stack
        className="summit-stats-component  hidden lg:flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase text-center"
        >
          Why Care is the Next Global Priority
        </Typography>

        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="py-8 text-center "
        >
          The care crisis is global, impacting economies, human capital
          development, and the well-being of families across generations.
        </Typography>

        <Box className="lg:flex lg:justify-center lg:gap-10 ">
          <StatsCard
            stats="12.5B"
            info="Globally, women perform an average of 3 times more unpaid care work than men, with estimates from the International Labour Organization (ILO) showing that women contribute 12.5 billion hours of unpaid care work every day. This unpaid labor has an economic value equivalent to 9% of global GDP—yet it remains invisible in policy discussions."
          />
          <StatsCard
            stats="40%"
            info="In many countries, childcare is inaccessible and unaffordable. According to UNICEF, 40% of children under the age of five worldwide lack access to formal childcare services, leaving parents, especially women, to shoulder the burden of care. The economic impact of this lack of access is staggering: in the United States alone, inadequate childcare results in $57 billion in lost productivity, earnings, and revenue annually"
          />
          <StatsCard
            stats="2.1B"
            info="As populations age, the demand for eldercare is surging. By 2050, the global population aged 60 and above will double to 2.1 billion, with many elderly people requiring long-term care. Yet, the care workforce is severely underfunded, and informal family caregivers often receive little to no support."
          />
          {/* <StatsCard
            stats="104K"
            info="Of the workforce missed work in October 2022 due to childcare shortages (Census Bureau)"
          /> */}
        </Box>
      </Stack>

      {/* Tab & Mobile */}

      <Stack
        className="summit-stats-component-tabmobile lg:hidden flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase text-center"
        >
          Why Care is the Next Global Priority
        </Typography>

        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="py-8  text-center w-[90%]"
        >
          The care crisis is global, impacting economies, human capital
          development, and the well-being of families across generations.
        </Typography>

        <Box className="block">
          <StatsCard
            stats="12.5B"
            info="Globally, women perform an average of 3 times more unpaid care work than men, with estimates from the International Labour Organization (ILO) showing that women contribute 12.5 billion hours of unpaid care work every day. This unpaid labor has an economic value equivalent to 9% of global GDP—yet it remains invisible in policy discussions."
          />
          <StatsCard
            stats="40%"
            info="In many countries, childcare is inaccessible and unaffordable. According to UNICEF, 40% of children under the age of five worldwide lack access to formal childcare services, leaving parents, especially women, to shoulder the burden of care. The economic impact of this lack of access is staggering: in the United States alone, inadequate childcare results in $57 billion in lost productivity, earnings, and revenue annually"
          />
          <StatsCard
            stats="2.1B"
            info="As populations age, the demand for eldercare is surging. By 2050, the global population aged 60 and above will double to 2.1 billion, with many elderly people requiring long-term care. Yet, the care workforce is severely underfunded, and informal family caregivers often receive little to no support."
          />
          {/* <StatsCard
            stats="104K"
            info="Of the workforce missed work in October 2022 due to childcare shortages (Census Bureau)"
          /> */}
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default SummitStats;
