import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import Slider from "react-slick";
import { SUMMIT_GALLERY } from "../../utils/landing-data";

import { ReactComponent as LeftBtn } from "../../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../../static/svg/right-btn.svg";
import { ReactComponent as PlayIcon } from "../../static/svg/play-btn.svg";
import { Link } from "react-router-dom";

type Props = {};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[100%] right-[30%] md:top-[45%] z-10 md:right-1 lg:right-14 ">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[100%] left-[30%] md:top-[45%] z-10 md:left-1 lg:left-14">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const SummitGallery = (props: Props) => {
  const [slideIndex, setSlideIndex] = React.useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "220px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsTab = {
    centerMode: true,
    centerPadding: "110px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsMobile = {
    centerMode: true,
    centerPadding: "50px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <React.Fragment>
      <Box className="h-[567px] md:h-[750px] lg:h-[860px] bg-navy-900 overflow-x-hidden text-center">
        <Box className="py-10 hidden md:block">
          <Typography
            variant="caption"
            fontSize={{ xs: "11px", sm: "11px" }}
            fontWeight={900}
            lineHeight={{ xs: "247%", sm: "102%" }}
            letterSpacing={"0.1em"}
            color={"white"}
            className=" uppercase text-center"
          >
            {/* A glance of the summit */}A glance of our past events
          </Typography>
        </Box>

        <Box className="py-10 block md:hidden">
          <Typography
            variant="caption"
            fontSize={{ xs: "11px", sm: "11px" }}
            fontWeight={900}
            lineHeight={{ xs: "247%", sm: "102%" }}
            letterSpacing={"0.1em"}
            color={"white"}
            className=" uppercase text-center"
          >
            The Gallery
          </Typography>
        </Box>

        {/* Web */}
        <Box className="hidden lg:block">
          <Slider
            {...settings}
            beforeChange={(current, next) => setSlideIndex(next)}
          >
            {SUMMIT_GALLERY.map((gallery, index) => (
              <Box
                key={index}
                className={
                  index === slideIndex
                    ? " opacity-100 scale-100 relative"
                    : " opacity-75 scale-75 blur-[1px] "
                }
              >
                <img
                  src={gallery.imgSrc}
                  alt={gallery.title}
                  className="w-[1070px] h-[700px] object-cover object-center rounded-[6px]"
                />

                {/* <Link to={"/"} className="no-underline">
                  <PlayIcon
                    className={`absolute top-[40%]  right-[45%] mt-12 w-20 h-20 cursor-pointer`}
                  />
                </Link> */}
              </Box>
            ))}
          </Slider>
        </Box>

        {/* Tabs */}

        <Box className="hidden md:block lg:hidden">
          <Slider
            {...settingsTab}
            beforeChange={(current, next) => setSlideIndex(next)}
          >
            {SUMMIT_GALLERY.map((gallery, index) => (
              <Box
                key={index}
                className={
                  index === slideIndex
                    ? " opacity-100 scale-100 relative"
                    : " opacity-75 scale-75  blur-[1px] "
                }
              >
                <img
                  src={gallery.imgSrc}
                  alt={gallery.title}
                  className="w-[1000px] h-[600px] object-cover object-center rounded-[6px]"
                />

                {/* <Link to={"/"} className="no-underline">
                  <PlayIcon
                    className={`absolute top-[35%] right-[43%] mt-12 w-20 h-20 cursor-pointer`}
                  />
                </Link> */}
              </Box>
            ))}
          </Slider>
        </Box>

        {/* Mobile */}

        <Box className="block md:hidden">
          <Slider
            {...settingsMobile}
            beforeChange={(current, next) => setSlideIndex(next)}
          >
            {SUMMIT_GALLERY.map((gallery, index) => (
              <Box
                key={index}
                className={
                  index === slideIndex
                    ? " opacity-100 scale-100 relative mb-8"
                    : " opacity-75 scale-75  blur-[1px] "
                }
              >
                <img
                  src={gallery.imgSrc}
                  alt={gallery.title}
                  className="w-[317px] h-[317px] object-cover object-center rounded-[6px]"
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SummitGallery;
