import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import MHDialog from "../Common/Dialog/MHDialog";
import MHFormControl from "../Common/Form/MHFormControl";
import MHButton from "../Common/Button/MHButton";
import useInput from "../../hooks/use-input";

import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";
import { MHSelect } from "../Common/Form/MHSelect";

///firebase
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { resolveErrorMessage } from "../../utils/utils";
import useForm from "../../hooks/use-form";
import useDialog from "../../hooks/use-dialog";
import MHFormGroup from "../Common/Form/MHFormGroup";
import RequestConfirmationPrompt from "../Contact/RequestConfirmation";
import { CONTACT_MODE } from "../../utils/landing-data";
import MHPhoneInput from "../Common/PhoneInput/MHPhoneInput";
import usePhoneInput from "../../hooks/use-phone";
import { MuiTelInputInfo } from "mui-tel-input";

const BlessingDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    value: enteredName,
    valid: enteredNameIsValid,
    error: enteredNameHasError,
    onChange: nameInputChangeHandler,
    onBlur: nameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPosition,
    valid: enteredPositionIsValid,
    error: enteredPositionHasError,
    onChange: positionInputChangeHandler,
    onBlur: positionInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredContactMode,
    valid: enteredContactModeIsValid,
    error: enteredContactModeHasError,
    onChange: contactModeInputChangeHandler,
    onBlur: contactModeInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredFocus,
    valid: enteredFocusIsValid,
    error: enteredFocusHasError,
    onChange: focusInputChangeHandler,
    onBlur: focusInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredPurpose,
    valid: enteredPurposeIsValid,
    error: enteredPurposeHasError,
    onChange: purposeInputChangeHandler,
    onBlur: purposeInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredFormat,
    valid: enteredFormatIsValid,
    error: enteredFormatHasError,
    onChange: formatInputChangeHandler,
    onBlur: formatInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredGoals,
    valid: enteredGoalsIsValid,
    error: enteredGoalsHasError,
    onChange: goalsInputChangeHandler,
    onBlur: goalsInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);
  const {
    value: enteredMessages,
    valid: enteredMessagesIsValid,
    error: enteredMessagesHasError,
    onChange: messagesInputChangeHandler,
    onBlur: messagesInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const [completed, setCompleted] = React.useState(false);

  let formIsValid =
    enteredNameIsValid &&
    enteredWorkEmailIsValid &&
    enteredCompanyNameIsValid &&
    enteredPositionIsValid &&
    phoneNumber &&
    enteredFocusIsValid &&
    enteredPurposeIsValid &&
    enteredFormatIsValid &&
    enteredGoalsIsValid &&
    enteredMessagesIsValid &&
    enteredContactModeIsValid;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "blessing-meeting-requests"), {
      Name: enteredName,
      WorkEmail: enteredWorkEmail,
      CompanyName: enteredCompanyName,
      Position: enteredPosition,
      Phone: phoneNumber,
      ContactMode: enteredContactMode,
      Focus: enteredFocus,
      Purpose: enteredPurpose,
      Format: enteredFormat,
      Goals: enteredGoals,
      Messages: enteredMessages,
      createdAt: serverTimestamp(),
    });

    handleOpenDialog();
    onClose();
    resetForm();

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const { validateForm } = useForm();

  const resetForm = () => {
    nameInputChangeHandler("");
    workEmailInputChangeHandler("");
    companyNameInputChangeHandler("");
    positionInputChangeHandler("");
    contactModeInputChangeHandler("");
    focusInputChangeHandler("");
    purposeInputChangeHandler("");
    formatInputChangeHandler("");
    goalsInputChangeHandler("");
    messagesInputChangeHandler("");
    phoneNumberInputChangeHandler("", {} as MuiTelInputInfo);
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={!completed ? " " : " "}
        handleClose={onClose}
        scroll="paper"
        actions={null}
        maxWidth={openDialog ? "xs" : "sm"}
        fullWidth
      >
        <MHFormGroup
          onSubmit={submitHandler}
          disableWhileSubmitting={httpState.loading}
        >
          <Typography
            variant="h2"
            fontSize={{ xs: "24px", sm: "28px", md: "30px" }}
            lineHeight={{ xs: "142%", sm: "143.5%" }}
            letterSpacing={"-0.04em"}
            className="capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            paddingBottom={4}
          >
            Request Blessing For Your Org
          </Typography>

          <Typography
            variant="body1"
            fontSize={{ xs: "13px", sm: "18px", md: "18px" }}
            lineHeight={{ xs: "200%", sm: "169%" }}
            letterSpacing={"0.02em"}
            className=" capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            paddingBottom={4}
            // sx={{ width: "80%" }}
          >
            Provide some information.
          </Typography>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="name"
                type="text"
                label="Full Name"
                placeholder="Name of the person making the request"
                value={enteredName}
                onChange={nameInputChangeHandler}
                onBlur={nameInputBlurHandler}
                error={resolveErrorMessage(enteredNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
                required
                // error={merchantNameErrorTip}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MHFormControl
                id="email"
                type="text"
                label=" Email"
                placeholder="Enter your email address"
                value={enteredWorkEmail}
                onChange={workEmailInputChangeHandler}
                onBlur={workEmailInputBlurHandler}
                required
                error={resolveErrorMessage(enteredWorkEmailHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="companyName"
                type="text"
                label="Organization / Company"
                placeholder="Who do you work for?"
                value={enteredCompanyName}
                onChange={companyNameInputChangeHandler}
                onBlur={companyNameInputBlurHandler}
                required
                error={resolveErrorMessage(enteredCompanyNameHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="position"
                type="text"
                label="Job Titles"
                placeholder="What is your role?"
                value={enteredPosition}
                onChange={positionInputChangeHandler}
                onBlur={positionInputBlurHandler}
                required
                error={resolveErrorMessage(enteredPositionHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHSelect
                label="Preferred mode of contact"
                placeholder="How can we contact you?"
                options={CONTACT_MODE}
                value={enteredContactMode}
                onChange={(val) => contactModeInputChangeHandler(val as string)}
                onBlur={contactModeInputBlurHandler}
                error={resolveErrorMessage(enteredContactModeHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHPhoneInput
                value={phoneNumber}
                onChange={phoneNumberInputChangeHandler}
                label="Phone Number"
                errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                  "Please enter a valid phone number"
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="purpose"
                type="text"
                label="Purpose of request"
                placeholder="E.g., speaking engagement, one-on-one session"
                value={enteredPurpose}
                onChange={purposeInputChangeHandler}
                onBlur={purposeInputBlurHandler}
                required
                error={resolveErrorMessage(enteredPurposeHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="focus"
                type="text"
                label="Focus Area"
                placeholder="Desired topic(s) or focus areas for the engagement/session"
                value={enteredFocus}
                onChange={focusInputChangeHandler}
                onBlur={focusInputBlurHandler}
                required
                error={resolveErrorMessage(enteredFocusHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="format"
                type="text"
                label="Preferred format"
                placeholder="Keynote speech, panel discussion, fireside chat, Q&A, etc."
                value={enteredFormat}
                onChange={formatInputChangeHandler}
                onBlur={formatInputBlurHandler}
                required
                error={resolveErrorMessage(enteredFormatHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MHFormControl
                id="outcome"
                type="text"
                label="Goals / Outcomes"
                placeholder="Expectations from the engagement/session"
                value={enteredGoals}
                onChange={goalsInputChangeHandler}
                onBlur={goalsInputBlurHandler}
                required
                error={resolveErrorMessage(enteredGoalsHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={12}>
              <MHFormControl
                id="message"
                type="text"
                label="Any specific requests or requirements?"
                placeholder="Start Typing..."
                value={enteredMessages}
                onChange={messagesInputChangeHandler}
                onBlur={messagesInputBlurHandler}
                required
                multiline
                maxRows={4}
                error={resolveErrorMessage(enteredMessagesHasError)(
                  constants.REQUIRED_ERROR_TIP
                )}
              />
            </Grid>
          </Grid>

          <MHButton
            fullWidth
            sx={{ paddingY: 2, marginY: 2 }}
            type="submit"
            // onClick={submitHandler}
            // onClick={() => handleOpenDialog()}
            loading={httpState.loading}
            className=" text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] "
          >
            Submit
          </MHButton>
        </MHFormGroup>
      </MHDialog>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="We'll be in touch soon!"
          message="Thank you for your interest in MH WorkLife. We will be in touch with
          you shortly."
        />
      )}
    </React.Fragment>
  );
};

export default BlessingDialog;
