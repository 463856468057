import { SelectOption } from '@mui/base';

export const LANDING_QUESTIONS_OPTIONS = [
    {
      label: 'How MH can amplify my employees daily work and personal life',
      value: 'How MH can amplify my employees daily work and personal life'
    },
    {
      label: 'How can MH help me start fresh',
      value: 'How can MH help me start fresh'
    },
    {
      label: 'How can MH help me flourish at work',
      value: 'How can MH help me flourish at work'
    }
  ];

  export const CARE_SUMMIT_SPEAKERS_DATA = [
    
    {
      name: 'Blessing Adesiyan',
      company: 'MH WorkLife',
      title: 'Founder & CEO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png'
    },
    {
      name: 'Abbey Carlton',
      company: `Social Impact, Indeed`,
      title: 'Vice President',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Abbey+headshot.jpeg'
    },
    {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Anna+HEadshot.jpg",
      name: "Anna Edwards",
      company: "Amazon",
      title: "Senior Manager for Global Accessibility Optimization"
  },
  {
      company: "Happyly",
      title: "Founder & CEO",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Caitlin.png",
      name: "Caitlin Iseler"
  },
    {
      name: 'Donald Knight',
      company: `Greenhouse`,
      title: 'Chief People Officer',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Donald.jpg'
    }, 
    {
      name: 'Sascha Mayer',
      company: 'Mamava',
      title: 'Co-Founder & CXO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117594/Flourish/SaschaMayer_lgpoak_xb0rna.png'
    },
    {
      name: 'Lauren Dai',
      company: `Cocoon`,
      title: 'Co-founder and COO',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/LaurenDai.jpeg'
    },
    {
      name: 'Siran Cao',
      company: `Hey Mirza`,
      title: 'CEO & Co-Founder',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Siran+Cao.jpg'
    },
    {
      name: 'Sarah Olin',
      company: `LUMO`,
      title: 'Founder & CEO',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Sarah+Olin.jpg'
    },
    {
      name: 'Lauren Perrotta',
      company: 'Vivvi',
      title: 'VP, Employer Partnerships',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Lauren+Perrotta+.png'
    },
    {
      name: 'Fatima Goss Graves',
      company: `National Women's Law Center`,
      title: 'President & CEO',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Fatima.jpg'
    },
    {
      name: 'Paul Saiedi',
      company: 'Uber',
      title: 'Director of DE & I',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Paul-Nezaum-Saiedi-headshot.webp'
    },
    // {
    //   name: 'Stephanie LeBlanc-Godfrey',
    //   company: 'Google',
    //   title: 'Head Of Inclusion',
    //   imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/steph.jpg'
    // },
    {
      name: 'Kristin Rowe-Finkbaker',
      company: `Moms Rising`,
      title: 'Executive Director',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/KristinRowe.png'
    },
    {
      name: 'Jocelyn Frye',
      company: `National Partnership for Women and Families`,
      title: 'President',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Jocelyn.jpg'
    },
    {
      name: 'Dinah Alobeid',
      company: `Greenhouse`,
      title: 'Senior Director of Content and Communications',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Dinah.jpg'
    },
    {
      name: 'Paul Sullivan',
      company: `The Company of Dads`,
      title: 'Founder',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Paul+Sullivan.jpg'
    },
  
   
    {
      name: 'Neha Ruch',
      company: `Mother Untitled`,
      title: 'Founder',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Neha.jpeg'
    },
   
    {
      name: 'Anna Steffeney',
      company: 'FamTech.Org',
      title: 'Executive Director',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Anna.jpg'
    },
    {
      name: 'Katherine Goldstein',
      company: 'Better Life Lab at New America',
      title: 'Care Reporting Fellow',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/KatherineGoldstein.jpg'
    },
    {
      name: 'Rebecca Gale',
      company: 'Better Life Lab at New America',
      title: 'Reporting Fellow',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/RGale+Headshot.jpg'
    },
    {
      name: 'Randi Braun',
      company: 'Something Major',
      title: 'Author & CEO',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Randi.jpeg'
    },
  

    {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Liat.png",
      title: " Senior Advisor, Workforce & Head of Child Care Strategy",
      name: "Liat Krawczyk",
      company: "CHIPS for America "
  },
  {
      title: "COO",
      name: "Molly Day",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/molly.png",
      company: "MomsFirst"
  },
  {
      name: "Nikki Adamson",
      company: "Hustle Hunters",
      title: "Founder",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/nikki-mh.png"
  },
  {
      title: "CEO",
      company: "Wellthy",
      name: "Lindsay Jurist-Rosner",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Lindsay+Jurist-Rosner.jpeg"
  },
  {
      company: "Glossier Inc.",
      title: "Director, Culture, Inclusion, and Internal Communications",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Halah+Alqahtani.png",
      name: "Halah AlQahtani"
  },
  {
      company: "Athletes Unlimited",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Jon-Patricof.png",
      name: "Jon Patricof",
      title: "CEO and Co-Founder"
  },
  {
      company: "Winnie",
      title: "CEO and Co-founder",
      name: "Sara Mauskopf",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/sarah-mauskop.png"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Sehreen.png",
      company: "Sleuth",
      name: "Sehreen Noor Ali ",
      title: "Co-Founder & President"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Julia-Edelstein.jpeg",
      name: "Julia Edelstein",
      title: "Editor & Journalist"
  },
 
  {
      title: "Editor-in-Chief",
      name: "Niven McCall-Mazza",
      company: "theSkimm",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Niven.jpeg"
  },
  {
      name: "Tara Henning",
      company: "Superkin",
      title: "Founder",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Tara.jpg"
  },
  {
      name: "Deborah Porter",
      title: "Workplace Parent Consultant",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Deborah.jpeg",
      company: "Porter Systems LLC "
  }
    
  ]


  export const SPEAKERS_DATA = [
    
    {
      name: 'Blessing Adesiyan',
      company: 'MH WorkLife',
      title: 'Founder & CEO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png'
    },
    
    {
      name: 'Chris Hyams',
      company: 'Indeed',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680271558/speakers/Chris_Hyams_bb4pkx.jpg'
    },
    {
      name: 'Brian Little',
      company: 'Intel',
      title: 'VP, Human Resources',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680271941/speakers/BrianLittle_kp0pdj.png'
    },
    {
      name: 'Abbey Carlton',
      company: 'Social Impact, Indeed',
      title: 'Vice President',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Abbey+headshot.jpeg'
    },
    {
        name: "Anna Edwards",
        imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Anna+HEadshot.jpg",
        title: "Senior Manager for Global Accessibility Optimization",
        company: "Amazon"
    },
    {
      name: 'Gayatri Agnew',
      company: 'Walmart',
      title: 'Senior Director',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680002450/speakers/Gayatri_Agnew_azhaic.jpg'
    },
    {
      company: "Greenhouse",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Donald.jpg",
      name: "Donald Knight",
      title: "Chief People Officer"
  },
  {
      company: "Cocoon",
      name: "Lauren Dai",
      title: "Co-founder and COO",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/LaurenDai.jpeg"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Siran+Cao.jpg",
      title: "CEO & Co-Founder",
      name: "Siran Cao",
      company: "Hey Mirza"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Sarah+Olin.jpg",
      company: "LUMO",
      title: "Founder & CEO",
      name: "Sarah Olin"
  },
  {
      name: "Lauren Perrotta",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Lauren+Perrotta+.png",
      company: "Vivvi",
      title: "VP, Employer Partnerships"
  },
  {
      name: "Fatima Goss Graves",
      title: "President & CEO",
      company: "National Women's Law Center",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Fatima.jpg"
  },
  {
      name: "Paul Saiedi",
      company: "Uber",
      title: "Director of DE & I",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Paul-Nezaum-Saiedi-headshot.webp"
  },
  {
      company: "National Partnership for Women and Families",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Jocelyn.jpg",
      name: "Jocelyn Frye",
      title: "President"
  },
  {
      company: "Moms Rising",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/KristinRowe.png",
      title: "Executive Director",
      name: "Kristin Rowe-Finkbaker"
  },
  {
      name: "Dinah Alobeid",
      company: "Greenhouse",
      title: "Senior Director of Content and Communications",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Dinah.jpg"
  },
  {
      company: "The Company of Dads",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Paul+Sullivan.jpg",
      title: "Founder",
      name: "Paul Sullivan"
  },
  {
      title: "Founder",
      company: "Mother Untitled",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Neha.jpeg",
      name: "Neha Ruch"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/KatherineGoldstein.jpg",
      company: "Better Life Lab at New America",
      title: "Reporting Fellow",
      name: "Katherine Goldstein"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/RGale+Headshot.jpg",
      name: "Rebecca Gale",
      company: "Better Life Lab at New America",
      title: "Reporting Fellow"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Randi.jpeg",
      title: "Author & CEO",
      name: "Randi Braun",
      company: "Something Major"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Liat.png",
      name: "Liat Krawczyk",
      company: "CHIPS for America ",
      title: " Senior Advisor, Workforce & Head of Child Care Strategy"
  },
  {
      name: "Molly Day",
      title: "COO",
      company: "MomsFirst",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/molly.png"
  },
  {
      title: "Founder",
      name: "Nikki Adamson",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/nikki-mh.png",
      company: "Hustle Hunters"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Lindsay+Jurist-Rosner.jpeg",
      name: "Lindsay Jurist-Rosner",
      title: "CEO",
      company: "Wellthy"
  },
  {
      company: "Glossier Inc.",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Halah+Alqahtani.png",
      title: "Director, Culture, Inclusion, and Internal Communications",
      name: "Halah AlQahtani"
  },
  {
      company: "Athletes Unlimited",
      name: "Jon Patricof",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Jon-Patricof.png",
      title: "CEO and Co-Founder"
  },
  {
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/sarah-mauskop.png",
      title: "CEO and Co-founder",
      name: "Sara Mauskopf",
      company: "Winnie"
  },
  {
      company: "Sleuth",
      name: "Sehreen Noor Ali ",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Sehreen.png",
      title: "Co-Founder & President"
  },
  {
      name: "Julia Edelstein",
      title: "Editor & Journalist",
      company: "",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Julia-Edelstein.jpeg"
  },
  {
      company: "Happyly",
      name: "Caitlin Iseler",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Caitlin.png",
      title: "Founder & CEO"
  },
  {
      title: "Editor-in-Chief",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Niven.jpeg",
      name: "Niven McCall-Mazza",
      company: "theSkimm"
  },
  {
      company: "Superkin",
      title: "Founder",
      name: "Tara Henning",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Tara.jpg"
  },
  {
      company: "Porter Systems LLC ",
      name: "Deborah Porter",
      imgSrc: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Deborah.jpeg",
      title: "Workplace Parent Consultant"
  },
    {
      name: 'Dr. C Nicole Mason',
      company: 'IWPR',
      title: 'President & CEO | NonProfit Leader',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117591/Flourish/NicoleMason_kk5rsq_qaj7o9.png'
    },
    {
      name: 'Eve Rodsky',
      company: 'Fair Play Life',
      title: 'Author & Economist',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117575/Flourish/EveRodsky_f8x3ai_b26cge.png'
    },
    {
      name: 'Christine M. Carter',
      company: 'Lexia Learning',
      title: 'Content Director',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117576/Flourish/ChristineMichelCarter_rrghke_jlr2uz.png'
    },
    {
      name: 'Jack Mardock',
      company: 'Oyster',
      title: 'Co-Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680635876/speakers/jack_wqny1k.jpg'
    },
    {
      name: 'Kacy Flemming',
      company: 'Global Well-Being',
      title: 'Head',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680635876/speakers/kacy_s0xqym.jpg'
    },
    {
      name: 'Sascha Mayer',
      company: 'Mamava',
      title: 'Co-Founder & CXO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117594/Flourish/SaschaMayer_lgpoak_xb0rna.png'
    },
    {
      name: 'Reshma Saujani',
      company: 'Girls Who Code + Moms First US',
      title: 'Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688023007/speakers/reshma_t8xe9o.jpg'
    },
    {
      name: 'Natalie Mayslich',
      company: 'Care.com',
      title: 'President',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117595/Flourish/NatalieMayslich_hrdtvk_uerkci.png'
    },
    {
      name: 'Nicole Centeno',
      company: 'Splendid Spoon',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117595/Flourish/NicoleCenteno_nwffed_bxnhfi.png'
    },
    {
      name: 'Sarah Dorsett',
      company: 'Nanit',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117588/Flourish/SarahDorsett_vilopt_dr7myh.png'
    },
    {
      name: 'Sarah Hardy',
      company: 'Bobbie',
      title: 'Co-Founder & COO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117595/Flourish/SarahHardy_slrl4p_wwjv6y.png'
    },
    {
      name: 'Adeline Azrack',
      company: 'Fondation Chanel',
      title: 'Managing Director',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117558/Flourish/AdelineAzrack_fyferr_gyeadb.png'
    },
    {
      name: 'Audrey G. Kingo',
      company: 'MH WorkLife',
      title: 'Editor-In-Chief',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666286155/Flourish/AudreyGoodsonKingo_p9vuwk.png'
    },
    {
      name: 'Charles Bonello',
      company: 'Vivvi',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666289958/Flourish/CharlesBonello_qnruyc.png'
    },
    {
      name: 'Stephanie LeBlanc-Godfrey',
      company: 'Google',
      title: 'Head Of Inclusion',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688132563/speakers/stephanie_leblan_f3xhm2.png'
    },
    {
      name: 'Bolanle Williams-Olley',
      company: 'Mancini Duffy',
      title: 'CFO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688024415/speakers/bolanle_mwiacl.png'
    },
    {
      name: 'Brian Anderson',
      company: 'Fathering Together',
      title: 'Co-Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688024390/speakers/Brian_Anderson_ukmrm6.png'
    },
    {
      name: 'Halle Tecco',
      company: 'Rock Health, Natalist, EVP at Everly Well',
      title: 'Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688129454/speakers/Halle-Tecco_Natalist_Headshot_aoqnso.jpg'
    },
    {
      name: 'Anna Steffeney',
      company: 'FamTech.Org',
      title: 'Executive Director',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Anna.jpg'
    },
    {
      name: 'Ai-Jen Poo',
      company: 'Caring Across',
      title: 'Co-Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688131123/speakers/ai-jen-poo_rfljvn.png'
    },
    {
      name: 'Melinda Garrett',
      company: 'Childcare Innovation Lab, NYCEDC',
      title: 'Asst VP',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135954/speakers/Melinda_Garett_lyeptn.jpg'
    },
    {
      name: 'Nikki Beck Kamkar',
      company: 'M & A, Deloitte',
      title: 'Principal',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135958/speakers/nikki-beck_buatbj.jpg'
    },
    {
      name: 'Dr. Tana M. Session',
      company: 'TanaMSession.com',
      title: 'DEI Strategist',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135959/speakers/tana_lm14ac.jpg'
    },
    {
      name: 'Michael Perry',
      company: 'Maple',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135973/speakers/Michael-Perry_lfpeiy.png'
    },
    {
      name: 'Celinda F. Appleby',
      company: 'Visa',
      title: 'Global Director of Talent',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135975/speakers/celinda_yurpoo.png'
    },
    {
      name: 'Massella Dukuly',
      company: 'Charter',
      title: 'Head of Workplace Strategy & Innovation',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680725582/MASSELA_l8qrfr.jpg'
    }
  ];


  export const SUMMIT_GALLERY = [
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-4.jpeg'
      // imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/caw3.jpg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-6.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-10.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-9.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-7.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-11.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-12.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-1.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-2.jpeg'
    },
    {
      title: 'Care At Work 2024',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-3.jpeg'
    }
    
  ];

  export const SummitSlideData =  [
    {
      text: "Still excited after attending last week's MH WorkLife's  Care at Work Summit with Sarah Cardillo! I left feeling motivated to bring creative ideas back to our organization as we navigate the challenges of working parents and caregivers in public education. Thank you Blessing Adesiyan for an extraordinary event ",
      author: "Meg Santonacita",
    },
    {
      text: "Last week I attended the #CareatWorkSummit hosted by Blessing Adesiyan and MH WorkLife. The conversations were incredible, the crowd supportive and engaged, and I learned and met people who are inventing, building and advocating in the working parent space. It was motivating and encouraging. ",
      author: "Sara Madera",
    },
    {
      text: "The Start To Flourish event in New York hosted by Mother Honestly was incredible. The conference covered the current state of caregiving in the U.S. (not great) and tangible ways to support us",
      author: "Hilary Kinney, PMP",
    },
    {
      text: "Last week I got to fill my bucket by attending the MH WorkLife #CareAtWorkSummit hosted by Blessing Adesiyan! As a coach I primarily work with my clients over zoom so attending an in person summit and meeting so many people that I had only met over zoom was a thrill!",
      author: "Anna McKay",
    },
    {
      text: "Today I attended the #MotherHonestly summit in Detroit. We learned about everything from supporting other mamas and not being afraid to ask for help when you need it, to how to excel in your career without sacrificing being an involved parent. Definitely food for thought today, thankful to Blessing Adesiyan and Kristen Hall, PMP for putting together such an inspirational event!",
      author: "LAURA KANE, DIRECTOR OF RETAILS OPERATION, SHINOLA (LEADER)",
    },
    {
      text: "Companies that prioritize a caring workplace not only foster employee well-being but also witness marked improvements in productivity, retention, and overall profitability.",
      author: "Mckinsey & Co",
    },
    
    {
      text: "In the modern corporate landscape, a caring workplace is not an added luxury but a fundamental necessity. It directly correlates with enhanced team performance, reduced turnover rates, and improved bottom-line results.",
      author: "",
    },
    {
      text: "The events of the past 2 years have turned workplaces upside down. Under the highly challenging circumstances of the COVID-19 pandemic, many employees are struggling to do their jobs. Many feel like they're “always on” now that the boundaries between work and home have blurred. They're worried about their family's health and finances. Burnout is a real issue.",
      author: "McKinsey & Co. Women In The Workplace",
    },
    {
      text: "As the demographic fabric of our society evolves, businesses that adapt to create caring workplaces are better positioned to meet the diverse needs of their employees, reflecting a true understanding of contemporary workforce dynamics.",
      author: "",
    },
    {
      text: "A caring workplace transcends business metrics, touching the lives of its employees, fostering a culture of mutual respect, and building a legacy of genuine impact.",
      author: "",
    },
    {
      text: "Caregiving and career needn't be at odds. At the Summit, explore how to seamlessly weave them together, championing both roles with equal vigor.",
      author: "",
    },
    {
      text: "Advocating for yourself is the first step in reshaping workplaces. At the Summit, discover the keys to unlock your full potential as a caregiver in the professional world.",
      author: "",
    },
  ];

  
  export const CONTACT_TITLE_LEVEL: SelectOption<string>[] = [
    {
      label: "Executive",
      value: "Executive",
    },
    {
      label: "Executive Assistant",
      value: "Executive Assistant",
    },
    {
      label: "HR",
      value: "HR",
    },
    {
      label: "Manager",
      value: "Manager",
    },
  ];

  export const CONTACT_ROLE: SelectOption<string>[] = [
    {
      label: "CEO / Founder",
      value: "CEO / Founder",
    },
    {
      label: "COO",
      value: "COO",
    },
    {
      label: "HR Executive",
      value: "HR Executive",
    },
    {
      label: "Communications Manager",
      value: "Communications Manager",
    },
  ];

  export const CONTACT_MODE: SelectOption<string>[] = [
    {
      label: "Phone",
      value: "Phone",
    },
    {
      label: "Email",
      value: "Email",
    },
   
  ];

  export const CONTACT_STATE: SelectOption<string>[] = [
    {
      label: "New York",
      value: "New York",
    },
    {
      label: "Ohio",
      value: "Ohio",
    },
    {
      label: "Virginia",
      value: "Virginia",
    },
    {
      label: "Florida",
      value: "Florida",
    },
  ];

  export const CONTACT_EMPLOYEE_NO: SelectOption<string>[] = [
    {
      label: "10 - 50",
      value: "10 - 50",
    },
    {
      label: "50 - 100",
      value: "50 - 100",
    },
    {
      label: "100 - 150",
      value: "100 - 150",
    },
    {
      label: "150 - 200",
      value: "150 - 200",
    },
  ];

  export const CONTACT_INTEREST: SelectOption<string>[] = [
    {
      label: "General Information",
      value: "General Information",
    },
    {
      label: "Finance",
      value: "Finance",
    },
    {
      label: "Parenting",
      value: "Parenting",
    },
    {
      label: "Motherhood",
      value: "Motherhood",
    },
    {
      label: "Well being",
      value: "Well being",
    },
  ];
