import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import MuiLink from "@mui/material/Link";

import MHButton from "../Common/Button/MHButton";

// type Props = {}
const tourDetails = [
  {
    id: 0,
    title: "CHILDCARE & THE CARE WORKFORCE",
    location: "Lagos, Nigeria | October 17th",
    caption:
      "Strengthening Nigeria’s Childcare Models And Advancing Women's Economic Power ",
    content:
      "Women and families need childcare to unlock economic power, and care workers are the backbone of a thriving childcare ecosystem. These workers are often undervalued and underpaid. In Lagos, we’ll focus on the gaps in Nigeria’s childcare system and the challenges facing working parents, including how to uplift the care workforce, advocating for fair wages, better working conditions, and recognition of care workers’ critical role in society. This session will also highlight the economic opportunities that arise from investing in childcare and the care workforce.",
    link: "/resources/events/strengthening-nigerias-childcare-models-and-advancing-womens-economic-power/439",
  },
  {
    id: 1,
    title: "Caring Policies",
    location: "Washington DC | October 22nd",
    caption: "Accelerating Progress for Just and Inclusive Care Systems",
    content:
      "As the U.S. approaches a pivotal election, the time is now to make care a national priority. In Washington DC, we will push for policies that support families, caregivers, and care workers, advocating for paid family leave, childcare funding, and eldercare support. We'll focus on how the U.S. can lead the way with caring policies that ensure just and inclusive systems, making care a central issue ahead of the elections.",
    link: "/resources/events/caring-policies-for-wellbeing-economic-growth-and-social-progress/440",
  },
  {
    id: 2,
    title: "Caring Workplaces",
    location: "Austin, TX | October 25th",
    caption:
      "Creating Family-Friendly, Inclusive Workplaces That Support Caregivers",
    content:
      "With the workplace evolving rapidly, the need for care-conscious environments has never been more critical. In Austin, we'll dive into how businesses can build family-friendly, inclusive workplaces that support caregivers. From flexible work policies to caregiver benefits, we'll explore how investing in care infrastructure can boost productivity, retain talent, and foster a more balanced workforce.",
    link: "/resources/events/caring-workplaces/441",
  },

  {
    id: 3,
    title: "The Care Gap Summit",
    location: "Los Angeles, CA | October 29th",
    caption:
      "Care as a Global Imperative: Advancing Equity, Policy, and Innovation",
    content:
      "The Los Angeles stop of the Global Care Gap Tour will serve as the culminating summit, bringing together thought leaders, policymakers, businesses, and advocates to address the entire spectrum of the care economy. This summit will dive into five critical areas - caring workplace, caring policies, childcare and aging care, caregiving and men, and elevate care workers - offering actionable solutions for closing the global care gap and making care a priority in policy, workplaces, and communities.",
    link: "/resources/events/the-care-gap-summit-2024/442",
  },
  {
    id: 4,
    title: "Men, Money, and Care",
    location: "London, UK | November 7th",
    caption: "Engaging Men in Caregiving to Close the Gender Care Gap",
    content:
      "Care isn't just women's work—it's everyone's responsibility. In London, we'll tackle the cultural and structural barriers that prevent men from stepping into caregiving roles. We'll discuss how increasing male participation in care can help close the gender care gap, shift societal norms, and build stronger, more equitable families and communities.",
    link: "/resources/events/men-money-and-care/443",
  },
];

const ToursLink = () => {
  let history = useHistory();

  const handleClickOpen = (link: string) => {
    // history.push(`/employers`);
    window.open(link);
  };
  return (
    <React.Fragment>
      <section id="tour" className="my-4">
        <Box className="pt-10">
          <Box className="items-center place-content-center ">
            {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
          THE ENTERPRISE PLAN
        </Typography> */}

            {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
          Join the many forward-
          <br className="md:hidden block" />
          thinking companies <br /> supporting their employee caregivers
        </Typography> */}
            <Typography
              variant="h2"
              width={{ xs: "90%", md: "65%" }}
              fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
              lineHeight={"120.5%"}
              letterSpacing={"-0.04em"}
              mx="auto"
              className="py-8  text-center"
            >
              Care Conversations That Drive Change
            </Typography>

            {/* <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "100%", md: "70%" }}
            fontSize="13px"
            lineHeight="185%"
            letterSpacing="0.02em"
            align="center"
            mx="auto"
            paragraph
          >
            Our commitment to quality and safety means every caregiver we place
            undergoes a comprehensive process that ensures they're fully
            prepared to support your family's needs.
          </Typography> */}

            {/* Web */}

            <Box
              className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 items-center mx-auto text-center place-content-center"
              justifyContent="center"
            >
              {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
            Personalized Employee Work- <br /> Life Care Solutions
          </Typography> */}

              {tourDetails.map((tour) => (
                <Box
                  className="ml-6 mr-6 py-8 relative"
                  key={tour.id}
                  height={370}
                >
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "12px", sm: "13px" }}
                    lineHeight={"102%"}
                    letterSpacing={"0.1em"}
                    className="uppercase text-center"
                  >
                    {tour.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight={"102%"}
                    letterSpacing={"0.1em"}
                    className="py-2  text-center"
                  >
                    {tour.location}
                  </Typography>

                  <Typography
                    variant="body1"
                    // width={{ xs: "90%", md: "100%" }}
                    // mx="auto"
                    fontSize={{ xs: "10px", sm: "12px" }}
                    fontStyle="italic"
                    lineHeight={"102%"}
                    letterSpacing={"0.1em"}
                    className="pt-1 text-center"
                  >
                    {tour.caption}
                  </Typography>

                  <Typography
                    variant="body1"
                    fontSize={{ xs: "12px", sm: "12px", md: "13px" }}
                    lineHeight={"150%"}
                    letterSpacing={"0.02em"}
                    className="py-4  text-center  "
                  >
                    {tour.content}
                  </Typography>

                  <Box className="mx-auto text-center absolute bottom-2 left-0 right-0 py-2 md:py-0">
                    {/* <MHButton
                    sx={{
                      width: "fit-content",
                    }}
                    onClick={() => handleClickOpen(tour.link)}
                  >
                    Join The Tour
                  </MHButton> */}
                    <MuiLink
                      component={Link}
                      underline="always"
                      // to={"#"}
                      onClick={() => handleClickOpen(tour.link)}
                      to="#"
                      // display="block"
                      // align="left"
                      className="text-[#0060f0]  py-1 removeUnderline"
                    >
                      Join The Tour
                    </MuiLink>
                  </Box>
                </Box>
              ))}

              <Box className="ml-6 mr-6 ">
                <img
                  src="https://mh-resources-production.s3.us-west-1.amazonaws.com/tour+web+page.png"
                  alt=""
                  className="h-full w-full object-contain"
                />
              </Box>

              {/* &bull; */}
            </Box>

            {/* Mobile */}

            <Box className="md:hidden block items-center place-content-center mx-auto text-center">
              <Box className="ml-6 mr-6" height={330}>
                <img
                  src="https://mh-resources-production.s3.us-west-1.amazonaws.com/tour+web+page.png"
                  alt=""
                  className="w-full h-full "
                />
              </Box>
              {tourDetails.map((tour) => (
                <Box className="ml-6 mr-6 py-8" key={tour.id}>
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "12px", sm: "14px" }}
                    lineHeight={"102%"}
                    letterSpacing={"0.1em"}
                    className="uppercase text-center"
                  >
                    {tour.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight={"102%"}
                    letterSpacing={"0.1em"}
                    className="py-2  text-center"
                  >
                    {tour.location}
                  </Typography>

                  <Typography
                    variant="body1"
                    // width={{ xs: "90%", md: "100%" }}
                    // mx="auto"
                    fontSize={{ xs: "10px", sm: "12px" }}
                    fontStyle="italic"
                    lineHeight={"102%"}
                    letterSpacing={"0.1em"}
                    className="pt-1 text-center"
                  >
                    {tour.caption}
                  </Typography>

                  <Typography
                    variant="body1"
                    fontSize={{ xs: "12px", sm: "12px", md: "13px" }}
                    lineHeight={"150%"}
                    letterSpacing={"0.02em"}
                    className="py-4  text-center  "
                  >
                    {tour.content}
                  </Typography>

                  <Box className="mx-auto text-center py-2 md:py-0">
                    {/* <MHButton
                    sx={{
                      width: "fit-content",
                    }}
                    onClick={() => handleClickOpen(tour.link)}
                  >
                    Join The Tour
                  </MHButton> */}
                    <MuiLink
                      component={Link}
                      underline="always"
                      // to={"#"}
                      onClick={() => handleClickOpen(tour.link)}
                      to="#"
                      // display="block"
                      // align="left"
                      className="text-[#0060f0] py-1 mr-0 ml-auto removeUnderline"
                    >
                      Join The Tour
                    </MuiLink>
                  </Box>
                </Box>
              ))}

              {/* <Typography
            variant="body1"
            fontSize={{ xs: "12px" }}
            lineHeight={"170%"}
            letterSpacing={"0.02em"}
            className=" py-3 text-center"
          >
            Employees need help navigating life hurdles and
            <br /> the daily transitions between caregiving,
            <br /> work and life.
          </Typography> */}
            </Box>

            {/* <Box className="mx-auto text-center py-10 md:py-0">
          <MHButton
            sx={{
              width: 'fit-content'
            }}
            onClick={handleOpen}>
            Request a Demo
          </MHButton>
        </Box> */}
          </Box>

          <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
        </Box>
      </section>
    </React.Fragment>
  );
};

export default ToursLink;
