

  export const FAQ_MEMBERSHIP_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 6,
      question: "What is the MH Employer Dashboard?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    
];

export const FAQ_SummitEvent_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 6,
      question: "What is the MH Employer Dashboard?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    
];

export const FAQ_Content_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 6,
      question: "What is the MH Employer Dashboard?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 7,
      question: "What is the MH Employer Wallet?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    
];

export const FAQ_Employers_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 5,
      question: "Tell me more about the coaching and mentorship",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
   
    
];

export const FAQ_Consultants_Data =  [
    {
      id: 1,
      question: "What does MH Offer?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 2,
      question: "What does the personalized dashboard entail?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 3,
      question: "How about the concierge services?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
    {
      id: 4,
      question: "What does the onboarding process consists of?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra est enim odio cum sagittis cursus pulvinar. Eget nisi quisque nisi, hendrerit aliquam. Mattis amet lectus integer blandit egestas porttitor mauris arcu. Placerat vel pulvinar purus duis.", 
    },
  
    
];



export const FAQ_HeaderData =  [
  {
    title: "Memberships",
    linkTo: "membership",
    count: FAQ_MEMBERSHIP_Data.length,
  },
  {
    title: "Content",
    linkTo: "content",
    count: FAQ_Content_Data.length,
  },
  {
    title: "Summit & Events",
    linkTo: "summitandevent",
    count: FAQ_SummitEvent_Data.length,
  },
  {
    title: "Employers",
    linkTo: "employers",
    count: FAQ_Employers_Data.length,
  },
  {
    title: "Consultants",
    linkTo: "consultants",
    count: FAQ_Consultants_Data.length,
  }
];


export const Summit_FAQ_Data =  [
  {
    id: 1,
    question: "What is The Care Gap Summit?",
    answer: "The Care Gap Summit is a one-day event offering practical solutions to caregiving challenges. Connect with women, caregivers, employers, and leaders to drive meaningful change in creating supportive, care-focused workplaces.", 
  },
  {
    id: 2,
    question: "Where is the agenda?",
    answer: "The Care Gap Summit agenda is being finalized! We can't wait to share the great line-up of conversations, activations, and speakers with you. Expect the nation’s top leaders in parenting, caregiving, work, and life.", 
  },
  {
    id: 3,
    question: "Can My ERG, HR, or DEI members attend?",
    answer: "Yes, this Summit exists at the intersection of career and life. We will be discussion how to approach our lives in caregiving, work, and life.", 
  },
  // {
  //   id: 4,
  //   question: "Where Should I Stay?",
  //   answer: "Book surrounding hotels, Airbnb's, or other short-term rentals around Brooklyn NY. We will be sharing a list of our favourite hotels soon.", 
  // },
  {
    id: 5,
    question: "What are the event's COVID-19 safety precautions?",
    answer: "With the return to the live for Care At Work Summit, MH WorkLife is dedicated to ensuring a great experience and keeping you safe during any ongoing pandemic conditions. The event will continue to adhere to the CDC and local health agencies recommendations, and will update event health policies as necessary.", 
  },
  // {
  //   id: 6,
  //   question: "Why should I attend?",
  //   answer: "Be inspired to raise the bar in your own daily work, personal life, and at your own workplace through practical lessons from leaders who know what it takes to create, build, and sustain a place where everyone, no matter their background flourishes. Attend because you will learn from the nation’s experts, leaders, home front and work front catalyst across the country. You’ll listen first-hand to how these leaders and organizations solve for and champion care at home and in the workplace. Also come for the community, networking, and opportunities for your personal and professional goals.", 
  // },
  {
    id: 7,
    question: "Can I buy event tickets when I get to the Summit?",
    answer: "This event has a limited quantity of tickets available and they are offered on a first come, first served basis. For the best chances of getting a spot at all the events you’d like to attend, register online in advance to secure your place!", 
  },
  {
    id: 8,
    question: "Will you accept additional vendors, partners, and sponsors?",
    answer: "Yes. Connect with our network of vetted women, parents, and caregivers. We are incredibly grateful to our current partners, and will appreciate your business at this time. Please email hello@mhworklife.com for our sales prospectus.", 
  },
  // {
  //   id: 9,
  //   question: "What is the dress code?",
  //   answer: "You'll find a range of attire at the Summit but generally most attendees are in business casual. Temperatures in meeting places may fluctuate widely, we do recommend bringing a sweater or light jacket with you since personal preferences vary.", 
  // },
  // {
  //   id: 10,
  //   question: "Can I bring a guest to the Summit and networking events?",
  //   answer: "All attendees at the Summit and networking events must be registered attendees who purchased tickets.", 
  // },
  // {
  //   id: 11,
  //   question: "Can I send a substitute in my place?",
  //   answer: "You may send a substitute in your place at any time. All such requests must be submitted by email to hello@mhworklife.com. Only requests made by the original registrant will be honoured.", 
  // },
  {
    id: 12,
    question: "What is the refund policy?",
    answer: "We will not refund tickets at any time. You can transfer your ticket to another participant.", 
  },

];


export const Care_FAQ_Data = [
  {
    id: 1,
    question: "Childcare",
    answer: "As a parent, caring for a child is challenging, especially in today’s American economy. Quality child care is becoming harder to find and increasingly expensive. The cost of daycare for my kids now exceeds our housing expenses. But here's the thing: come November, we have the power to change that. By voting for leaders who prioritize affordable childcare, we can ensure that families get the support they need. It’s not just about us—it’s about our kids and their future. Let’s #VoteLikeYouCare for childcare.", 
  },
  {
    id: 3,
    question: "Disability Care",
    answer: "Caring for a loved one with a disability in this country is no small task, and the lack of support makes it even harder. Imagine an America where disability care is accessible, affordable, and prioritized. That’s the future we can build together if we #VoteLikeYouCare for disability care this November. Every person deserves dignity, and every family deserves support.", 
  },
  {
    id: 5,
    question: "Aging Care",
    answer: "Caring for an aging family member in this country is incredibly expensive, and many of us can't afford to take time off because we won’t get paid. But imagine an economy that prioritizes care, including aging care. That vision can become our reality if you #VoteLikeYouCare for aging care this November. Are you ready? Because I know I am.", 
  },
  {
    id: 4,
    question: "Paid Family Leave",
    answer: "As a working parent in America, taking leave often means not getting paid, whether you're caring for a sick family member, looking after your newborn, or recovering postpartum. This needs to change, which is why we must #VoteLikeYouCare for Paid Family Leave. Every working American deserves this essential support. Now, we have the power to elect a care-focused administration that values working parents and prioritizes their need.", 
  },
  {
    id: 2,
    question: "Reproductive Care",
    answer: "Women’s reproductive freedom is restricted across different states in the country, and the stakes couldn’t be higher. Imagine a world where every woman has the right to make decisions about her own body without fear or interference. That’s the future we can create if we #VoteLikeYouCare for women’s reproductive freedom this November. It’s time to stand up, speak out, and protect the rights that generations before us fought to secure. ", 
  },
  {
    id: 6,
    question: "Care Workers",
    answer: "America’s care workforce provides essential services for children, older adults, and people with disabilities in home, community, and residential settings. These services are critical to our quality of life. Yet care workers earn low wages, work longer hours, and are treated with indignity and disrespect. Many are low-income black and latino women who also deserve care and America that works.", 
  },
  
]