import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as YellowIcon } from "../../static/svg/eventyellow.svg";

import MHButton from "../Common/Button/MHButton";
import Overlay from "../UI/Overlay";

// type Props = {}

const EventAdvert = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/summit`);
  };
  return (
    <React.Fragment>
      <Box>
        <Box className="relative overflow-hidden">
          {/* <EventAdvertPic className="w-fit hidden md:block h-[700px] object-cover" /> */}
          <Box position="relative">
            <Overlay />
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1688732100/MotherHonestly_StartToFlourish-228_o7qneq.jpg"
              alt=""
              className="w-full hidden md:block h-[700px] object-cover"
            />
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1688732100/MotherHonestly_StartToFlourish-228_o7qneq.jpg"
              alt=""
              className="w-full block md:hidden h-[700px] object-cover"
            />
          </Box>

          <Box className="absolute top-[5%] md:top-[15%] text-start md:text-center left-[20px] md:left-0 md:right-0">
            <YellowIcon className="md:mx-auto" />

            <Typography
              variant="caption"
              fontSize={{ xs: "12px", sm: "15px", md: "18.3px" }}
              lineHeight={{ xs: "100%", sm: "189%" }}
              letterSpacing={"0.1em"}
              color={"white"}
              className="py-4 uppercase text-start md:text-center"
            >
              OCT 29th, 2024 | 10AM EST
            </Typography>

            {/* <Typography className=" font-columbia text-[40px] text-start md:text-center md:text-[55px]  lg:text-[67.7px] leading-[103%] md:leading-[105.5%] tracking-[-0.05em] text-white ">
              Caring Workplaces Summit
            </Typography> */}
            <Typography
              variant="h2"
              fontSize={{ xs: "40px", sm: "55px", md: "67.7px" }}
              lineHeight={{ xs: "103%", sm: "105.5%" }}
              letterSpacing={"-0.05em"}
              color={"white"}
              className="text-start md:text-center "
            >
              The Care Gap Summit 2024
            </Typography>

            <Typography className="py-10 uppercase font-areaExt text-[11px] text-start md:text-center md:text-[10px]  lg:text-[12px] leading-[230%] md:leading-[102%] tracking-[0.1em] text-white ">
              Care as a Global Imperative: Advancing Equity, Policy, and
              Innovation{" "}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"-0.02em"}
              color={"white"}
              className=" text-start md:text-center w-[90%] md:w-[40%] lg:w-[30%] md:mx-auto"
            >
              The Los Angeles stop of the Global Care Gap Tour will serve as the
              culminating summit, bringing together thought leaders,
              policymakers, businesses, and advocates to address the entire
              spectrum of the care economy. This summit will dive into five
              critical areas, offering actionable solutions for closing the
              global care gap and making care a priority in policy, workplaces,
              and communities.
            </Typography>

            <Box className="mx-auto text-start md:text-center py-10 md:py-8">
              <MHButton
                onClick={() => handleClickOpen()}
                className="bg-white text-navy-900 text-[12px] leading-[102%] tracking-[0.05em]  w-[183px] h-[44px] md:w-[170px] lg:w-[183px] md:h-[50px] lg:h-[52px] py-5"
              >
                Register Today
              </MHButton>
            </Box>

            <Link to={"/resources/events"}>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "102%", sm: "105.5%" }}
                letterSpacing={{ xs: "0.05em", sm: "0.1em" }}
                color={"white"}
                className="py-8 md:py-12 capitalize underline text-start md:text-center "
              >
                Browse this Month's Events
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default EventAdvert;
