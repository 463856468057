import React from "react";
import SummitStats from "../components/Summit/SummitStats";
import StickyRegister from "../components/Summit/StickyRegister";
import SummitHeader from "../components/Summit/SummitHeader";
import SummitDetails from "../components/Summit/SummitDetails";
import SummitMotto from "../components/Summit/SummitMotto";
import SummitSpeakers from "../components/Summit/SummitSpeakers";
import SummitInfo from "../components/Summit/SummitInfo";
import SummitAttendee from "../components/Summit/SummitAttendee";
import SummitGallery from "../components/Summit/SummitGallery";
import YellowCard from "../components/Layout/YellowCard";
import SummitAgenda from "../components/Summit/SummitAgenda";
import YellowSlider from "../components/Layout/YellowSlider";
import { SummitSlideData } from "../utils/landing-data";
import MHFooter from "../components/Layout/MHFooter";
import SummitAdditionals from "../components/Summit/SummitAdditionals";
import SummitPricingWidget from "../components/Summit/SummitPricingWidget";
import SummitNavigator from "../components/Summit/SummitNavigator";
import SummitContext from "../store/context/summit-context";
import SummitFAQ from "../components/Summit/SummitFAQ";
import SummitSponsors from "../components/Summit/SummitSponsors";
import PresentingSponsors from "../components/Summit/PresentingSponsors";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";
import ToursLink from "../components/Summit/ToursLink";
import RecapVideo from "../components/Resources/SubComponents/RecapVideo";

// type Props = {};

const Summit = () => {
  useTitle("Summit");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Summit",
  });

  return (
    <React.Fragment>
      <SummitContext>
        <SummitHeader />
        <SummitNavigator displayHeight={650} />
        <SummitStats />
        <SummitDetails />
        <PresentingSponsors headerText="Partners" />
        <SummitInfo />
        <RecapVideo />
        <SummitMotto />
        {/* <SummitAttendee /> */}
        {/* <SummitAdditionals /> */}
        {/* <YellowCard
          text="The events of the past 2 years have turned workplaces upside down. Under the highly challenging circumstances of the COVID-19 pandemic, many employees are struggling to do their jobs. Many feel like they're “always on” now that the boundaries between work and home have blurred. They're worried about their family's health and finances. Burnout is a real issue."
          author="McKinsey & Co. Women In The Workplace"
        /> */}
        <ToursLink />
        <YellowCard
          text="Companies that prioritize a caring workplace not only foster employee well-being but also witness marked improvements in productivity, retention, and overall profitability."
          author="McKinsey & Co."
        />
        {/* <SummitSpeakers /> */}
        {/* <SummitAgenda /> */}
        <SummitGallery />
        <YellowSlider data={SummitSlideData} />
        {/* <SummitPricingWidget /> */}
        {/* <SummitSponsors /> */}

        <SummitFAQ />

        <MHFooter />
        <StickyRegister />
      </SummitContext>
    </React.Fragment>
  );
};

export default Summit;
